// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("listeners");
require("select2");

import { uppyMultipleFileUpload } from "./uppy_file_upload";
import { setClickListeners, setInputListeners } from "listeners/index";

document.addEventListener("turbolinks:load", event => {
  $('.select2').remove();
  $('.select-js select').removeClass('select2-hidden-accessible');
  $(".select-js select").select2({ tags: true });

  setClickListeners();
  setInputListeners();
  Array.from(document.querySelectorAll('.uppy-upload-file')).forEach((fileInput) => {
    uppyMultipleFileUpload(fileInput)
  })

  Array.from(document.querySelectorAll('.uppy-upload-single-file')).forEach((fileInput) => {
    uppyMultipleFileUpload(fileInput, { maxNumberOfFiles: 1 })
  })
})
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
