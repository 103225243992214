const Uppy = require('@uppy/core')
const XHRUpload = require('@uppy/xhr-upload')
const Dashboard = require('@uppy/dashboard')
const ProgressBar = require('@uppy/progress-bar')
const Informer = require('@uppy/informer')
const ThumbnailGenerator = require('@uppy/thumbnail-generator')
const FileInput = require('@uppy/file-input')
const AwsS3 = require('@uppy/aws-s3')

export function uppyMultipleFileUpload(fileInput, restrictions, customDashboardOptions) {
  var uppyTarget = fileInput.parentNode
  var formGroup  = fileInput.closest("[data-image-upload]")

  var fieldNameTemplate = fileInput.name

  var uppy = fileUpload(fileInput, restrictions)

  var openButton = formGroup.querySelector("[data-image-upload-button]")
  openButton.addEventListener("click", (e) => {
    e.preventDefault()
  })

  var dashboardOptions = {
    target: uppyTarget,
    trigger: openButton,
    inline: false,
    height: 300,
    replaceTargetContent: true,
    closeModalOnClickOutside: true,
    closeAfterFinish: true
  }

  uppy
    .use(Dashboard, Object.assign({}, dashboardOptions, customDashboardOptions))

  var index = new Date().getTime()

  uppy.on('upload-success', function (file, response) {
    var uploadedImagesLabel = formGroup.querySelector("[data-image-upload-label]")
    var noImageLabel = uploadedImagesLabel.querySelector('[data-no-image-label]')

    var hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.name = fieldNameTemplate.replace(/\[index\]/, `[${index}]`).replace(/\[\]/, '')
    hiddenField.value = uploadedFileData(file, response, fileInput)

    var fileLabel = document.createElement('div')
    fileLabel.classList.add("uploaded-file")
    fileLabel.classList.add("gallery__photos")
    var domString = `<div class="gallery__photo-link" style="margin-top: 20px"><img src="${file.preview}" class="gallery__photo"/><div class="gallery__delete"><button data-revome-uploaded-file=true class="gallery__delete-button">Poista kuva</button></div></div>`;
    fileLabel.innerHTML = domString;
    fileLabel.appendChild(hiddenField)

    index += 1

    fileLabel.addEventListener("click", (e) => {
      e.preventDefault()
      if (!e.target.dataset.revomeUploadedFile) {
        return
      }
      e.currentTarget.remove()
      toggleNoImageLable(noImageLabel)
      uppy.removeFile(file.id)
    })

    uploadedImagesLabel.appendChild(fileLabel)
    toggleNoImageLable(noImageLabel)
  })
}

function toggleNoImageLable(label, index) {
  var imageCnt = label.closest('[data-image-upload]').querySelectorAll('.uploaded-file').length
  if (imageCnt > 0) {
    label.style.display = "none";
    return
  }

  label.style.display = "block";
}

function fileUpload(fileInput, restrictions) {
  var uppyOptions = {
    id: fileInput.id,
    autoProceed: false,
    restrictions: {
      allowedFileTypes: fileInput.accept.split(','),
    }
  }
  uppyOptions.restrictions = Object.assign({}, uppyOptions.restrictions, restrictions)
  var uppy = Uppy(uppyOptions)

  uppy.use(AwsS3, {
    companionUrl: '/', // will call Shrine's presign endpoint mounted on `/s3/params`
  })

  return uppy
}

function uploadedFileData(file, response, fileInput) {
  // construct uploaded file data in the format that Shrine expects
  return JSON.stringify({
    id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
    storage: 'cache',
    metadata: {
      size:      file.size,
      filename:  file.name,
      mime_type: file.type,
    }
  })
}



