export function setClickListeners() {
  const openSidebar = document.getElementById("open-sidebar");
  const closeSidebar = document.getElementById("close-sidebar");
  const sidebar = document.getElementById("sidebar");
  const weekdayToggler = document.getElementById("weekday-toggler");
  const allWeekdays = document.getElementById("all-weekdays");

  if (openSidebar && closeSidebar && sidebar) {
    openSidebar.addEventListener("click", (e) => {
      sidebar.classList.toggle("sidebar--active");
      openSidebar.classList.toggle("header__mobile-toggle--active");
    });

    closeSidebar.addEventListener("click", (e) => {
      sidebar.classList.remove("sidebar--active");
      openSidebar.classList.remove("header__mobile-toggle--active");
    });
  }

  if (weekdayToggler && allWeekdays) {
    weekdayToggler.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      allWeekdays.classList.toggle("week-schedule__all-weekdays--visible");
      weekdayToggler.classList.toggle("active");
    });
  }
};

export function setInputListeners() {
  const mondayToFridayOpeningHoursFrom = document.getElementById(
    "monday-to-friday__opening-hours-from"
  );
  const mondayOpeningHoursFrom = document.getElementById(
    "monday__opening-hours-from"
  );
  const tuesdayOpeningHoursFrom = document.getElementById(
    "tuesday__opening-hours-from"
  );
  const wednesdayOpeningHoursFrom = document.getElementById(
    "wednesday__opening-hours-from"
  );
  const thursdayOpeningHoursFrom = document.getElementById(
    "thursday__opening-hours-from"
  );
  const fridayOpeningHoursFrom = document.getElementById(
    "friday__opening-hours-from"
  );

  const mondayToFridayOpeningHoursTo = document.getElementById(
    "monday-to-friday__opening-hours-to"
  );
  const mondayOpeningHoursTo = document.getElementById(
    "monday__opening-hours-to"
  );
  const tuesdayOpeningHoursTo = document.getElementById(
    "tuesday__opening-hours-to"
  );
  const wednesdayOpeningHoursTo = document.getElementById(
    "wednesday__opening-hours-to"
  );
  const thursdayOpeningHoursTo = document.getElementById(
    "thursday__opening-hours-to"
  );
  const fridayOpeningHoursTo = document.getElementById(
    "friday__opening-hours-to"
  );

  const mondayToFridayBlockedFrom = document.getElementById(
    "monday-to-friday__blocked-from"
  );
  const mondayBlockedFrom = document.getElementById("monday__blocked-from");
  const tuesdayBlockedFrom = document.getElementById("tuesday__blocked-from");
  const wednesdayBlockedFrom = document.getElementById(
    "wednesday__blocked-from"
  );
  const thursdayBlockedFrom = document.getElementById("thursday__blocked-from");
  const fridayBlockedFrom = document.getElementById("friday__blocked-from");

  const mondayToFridayBlockedTo = document.getElementById(
    "monday-to-friday__blocked-to"
  );
  const mondayBlockedTo = document.getElementById("monday__blocked-to");
  const tuesdayBlockedTo = document.getElementById("tuesday__blocked-to");
  const wednesdayBlockedTo = document.getElementById("wednesday__blocked-to");
  const thursdayBlockedTo = document.getElementById("thursday__blocked-to");
  const fridayBlockedTo = document.getElementById("friday__blocked-to");

  const mondayToFridayPriceDay = document.getElementById(
    "monday-to-friday__price-day"
  );
  const mondayPriceDay = document.getElementById("monday__price-day");
  const tuesdayPriceDay = document.getElementById("tuesday__price-day");
  const wednesdayPriceDay = document.getElementById("wednesday__price-day");
  const thursdayPriceDay = document.getElementById("thursday__price-day");
  const fridayPriceDay = document.getElementById("friday__price-day");

  const mondayToFridayPriceEvening = document.getElementById(
    "monday-to-friday__price-evening"
  );
  const mondayPriceEvening = document.getElementById("monday__price-evening");
  const tuesdayPriceEvening = document.getElementById("tuesday__price-evening");
  const wednesdayPriceEvening = document.getElementById(
    "wednesday__price-evening"
  );
  const thursdayPriceEvening = document.getElementById(
    "thursday__price-evening"
  );
  const fridayPriceEvening = document.getElementById("friday__price-evening");

  const mondayToFridayEveningCutoff = document.getElementById(
    "monday-to-friday__evening-cutoff"
  );
  const mondayEveningCutoff = document.getElementById("monday__evening-cutoff");
  const tuesdayEveningCutoff = document.getElementById(
    "tuesday__evening-cutoff"
  );
  const wednesdayEveningCutoff = document.getElementById(
    "wednesday__evening-cutoff"
  );
  const thursdayEveningCutoff = document.getElementById(
    "thursday__evening-cutoff"
  );
  const fridayEveningCutoff = document.getElementById("friday__evening-cutoff");

  const mondayToFridayClosed = document.getElementById(
    "monday-to-friday__closed"
  );
  const mondayClosed = document.getElementById("monday__closed");
  const tuesdayClosed = document.getElementById("tuesday__closed");
  const wednesdayClosed = document.getElementById("wednesday__closed");
  const thursdayClosed = document.getElementById("thursday__closed");
  const fridayClosed = document.getElementById("friday__closed");

  if (
    mondayToFridayOpeningHoursFrom &&
    mondayOpeningHoursFrom &&
    tuesdayOpeningHoursFrom &&
    wednesdayOpeningHoursFrom &&
    thursdayOpeningHoursFrom &&
    fridayOpeningHoursFrom
  ) {
    mondayToFridayOpeningHoursFrom.addEventListener("change", (e) => {
      mondayOpeningHoursFrom.value = e.target.value;
      tuesdayOpeningHoursFrom.value = e.target.value;
      wednesdayOpeningHoursFrom.value = e.target.value;
      thursdayOpeningHoursFrom.value = e.target.value;
      fridayOpeningHoursFrom.value = e.target.value;
    });
  }

  if (
    mondayToFridayOpeningHoursTo &&
    mondayOpeningHoursTo &&
    tuesdayOpeningHoursTo &&
    wednesdayOpeningHoursTo &&
    thursdayOpeningHoursTo &&
    fridayOpeningHoursTo
  ) {
    mondayToFridayOpeningHoursTo.addEventListener("change", (e) => {
      mondayOpeningHoursTo.value = e.target.value;
      tuesdayOpeningHoursTo.value = e.target.value;
      wednesdayOpeningHoursTo.value = e.target.value;
      thursdayOpeningHoursTo.value = e.target.value;
      fridayOpeningHoursTo.value = e.target.value;
    });
  }

  if (
    mondayToFridayBlockedFrom &&
    mondayBlockedFrom &&
    tuesdayBlockedFrom &&
    wednesdayBlockedFrom &&
    thursdayBlockedFrom &&
    fridayBlockedFrom
  ) {
    mondayToFridayBlockedFrom.addEventListener("change", (e) => {
      mondayBlockedFrom.value = e.target.value;
      tuesdayBlockedFrom.value = e.target.value;
      wednesdayBlockedFrom.value = e.target.value;
      thursdayBlockedFrom.value = e.target.value;
      fridayBlockedFrom.value = e.target.value;
    });
  }

  if (
    mondayToFridayBlockedTo &&
    mondayBlockedTo &&
    tuesdayBlockedTo &&
    wednesdayBlockedTo &&
    thursdayBlockedTo &&
    fridayBlockedTo
  ) {
    mondayToFridayBlockedTo.addEventListener("change", (e) => {
      mondayBlockedTo.value = e.target.value;
      tuesdayBlockedTo.value = e.target.value;
      wednesdayBlockedTo.value = e.target.value;
      thursdayBlockedTo.value = e.target.value;
      fridayBlockedTo.value = e.target.value;
    });
  }

  if (
    mondayToFridayPriceDay &&
    mondayPriceDay &&
    tuesdayPriceDay &&
    wednesdayPriceDay &&
    thursdayPriceDay &&
    fridayPriceDay
  ) {
    mondayToFridayPriceDay.addEventListener("change", (e) => {
      mondayPriceDay.value = e.target.value;
      tuesdayPriceDay.value = e.target.value;
      wednesdayPriceDay.value = e.target.value;
      thursdayPriceDay.value = e.target.value;
      fridayPriceDay.value = e.target.value;
    });
  }

  if (
    mondayToFridayPriceEvening &&
    mondayPriceEvening &&
    tuesdayPriceEvening &&
    wednesdayPriceEvening &&
    thursdayPriceEvening &&
    fridayPriceEvening
  ) {
    mondayToFridayPriceEvening.addEventListener("change", (e) => {
      mondayPriceEvening.value = e.target.value;
      tuesdayPriceEvening.value = e.target.value;
      wednesdayPriceEvening.value = e.target.value;
      thursdayPriceEvening.value = e.target.value;
      fridayPriceEvening.value = e.target.value;
    });
  }

  if (
    mondayToFridayEveningCutoff &&
    mondayEveningCutoff &&
    tuesdayEveningCutoff &&
    wednesdayEveningCutoff &&
    thursdayEveningCutoff &&
    fridayEveningCutoff
  ) {
    mondayToFridayEveningCutoff.addEventListener("change", (e) => {
      mondayEveningCutoff.value = e.target.value;
      tuesdayEveningCutoff.value = e.target.value;
      wednesdayEveningCutoff.value = e.target.value;
      thursdayEveningCutoff.value = e.target.value;
      fridayEveningCutoff.value = e.target.value;
    });
  }

  if (
    mondayToFridayClosed &&
    mondayClosed &&
    tuesdayClosed &&
    wednesdayClosed &&
    thursdayClosed &&
    fridayClosed
  ) {
    mondayToFridayClosed.addEventListener("change", (e) => {
      mondayClosed.checked = e.target.checked;
      tuesdayClosed.checked = e.target.checked;
      wednesdayClosed.checked = e.target.checked;
      thursdayClosed.checked = e.target.checked;
      fridayClosed.checked = e.target.checked;
    });
  }
};
